import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_IP_Camera_Binding/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB Setup under openHABian",
  "path": "/Advanced_User/openHABian_on_Raspberry_Pi/OpenHAB_Setup/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_openHABian.png",
  "social": "/images/Search/AU_SearchThumb_openHABian.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB Setup under openHABian' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_openHABian.png' twitter='/images/Search/AU_SearchThumb_openHABian.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/openHABian_auf_Raspberry_Pi/OpenHAB_Setup/' locationFR='/fr/Advanced_User/openHABian_on_Raspberry_Pi/OpenHAB_Setup/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "openhab-setup-under-openhabian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-setup-under-openhabian",
        "aria-label": "openhab setup under openhabian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Setup under openHABian`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-cli"
        }}>{`OpenHAB CLI`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#securing-your-installation"
            }}>{`Securing your Installation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-setup"
        }}>{`OpenHAB Setup`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "openhab-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-cli",
        "aria-label": "openhab cli permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB CLI`}</h2>
    <p>{`As to be expected from a LINUX distro maintained by OpenHAB, there isn't much to do anymore to set up OpenHAB once you installed openHABian on your Pi. openHABian offers a CLI tool that can be started with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo openhabian-config`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a591a82be72832490b811ac47600bae/e9beb/OpenHabian_CLI_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACDUlEQVQozy3I207aAAAA0H4no8B0OGVzi9FsZtkXuGlpC45bKSIIJnjhVkZAlEKBtrSlFxTdXAO0pe1AY7Jkr4vJ3k4OAHJTB687BN0h6g5Bcww0l2yAou6UNFAynJLuUgyPMvMMDVA2vNf662tjSTaXBG2ZGwOfWanGlYne91o1f1kpNCoFspRvVYt1onBRyrfqRKdOdBoEc/GMbqPY5SsNvrzVpN2CAeyy9B8paXYOfiX31BQ0Pws95ELjNHR/CN0nd6dZWM/C07R/fhI0MrB1hP7lMk9s5FOh4BRtYIdl7cGRKmQHFNa7/MaR0R9CWmhH2VaMJaNCM8y3ooNOnGlGmKuw2MO14cmEim2Xyi8EE/jCMnPx6CeTogmUPN2jy4EbMk4VEaoAU0W0W4SZMkoTwW4JHV1hEzZtKDm1FflYLLvEGfC135/LWZU/HrYTo87BmM/oQkZuYjKJD9u4TOJKC7/tJlUmpQvHlpLTh6cqFduuVEHZBmCOXdDRSQ836LjVT1gMblJhi8ZMOm7TmNbFtF5c62EWmzBZ3GTwOXc4rkEfzs6dkgW8Jfl1FFpDoDcI5EMgHwxt7cMbgWeswdAqDPlQ/7t9ZD2IbIQCm5HgZiT43r/jOq+7xRng5qagZIGSDYqWW7I9ymJ1tHil2B7Zdkm283/+Xr5ZrNw9rtw9ekcL7+2DV5697I//ASsie9oxl3fDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a591a82be72832490b811ac47600bae/e4706/OpenHabian_CLI_01.avif 230w", "/en/static/5a591a82be72832490b811ac47600bae/d1af7/OpenHabian_CLI_01.avif 460w", "/en/static/5a591a82be72832490b811ac47600bae/b6582/OpenHabian_CLI_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a591a82be72832490b811ac47600bae/a0b58/OpenHabian_CLI_01.webp 230w", "/en/static/5a591a82be72832490b811ac47600bae/bc10c/OpenHabian_CLI_01.webp 460w", "/en/static/5a591a82be72832490b811ac47600bae/87ca7/OpenHabian_CLI_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a591a82be72832490b811ac47600bae/81c8e/OpenHabian_CLI_01.png 230w", "/en/static/5a591a82be72832490b811ac47600bae/08a84/OpenHabian_CLI_01.png 460w", "/en/static/5a591a82be72832490b811ac47600bae/e9beb/OpenHabian_CLI_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a591a82be72832490b811ac47600bae/e9beb/OpenHabian_CLI_01.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The openHABian installation process already installed the latest version of OpenHAB and all necessary dependencies. But the CLI also allows you to install optional features - `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/"
      }}>{`we will get into that here`}</a>{`.`}</p>
    <h3 {...{
      "id": "securing-your-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#securing-your-installation",
        "aria-label": "securing your installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Securing your Installation`}</h3>
    <p>{`All services on your openHABian installation can be accessed with the `}<strong parentName="p">{`user: openhabian`}</strong>{` and the `}<strong parentName="p">{`password: openhabian`}</strong>{`, which is fine, if you are operating your Pi on a secure local network only you have access to. But you can change all password through the OpenHAB CLI under `}<em parentName="p">{`System Settings`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/40ca4212eb39a552d723bf442ee7cb97/e9beb/OpenHabian_CLI_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABy0lEQVQY0x3G626aYAAAUN5yD+Cm3VMsSyMFlaodtWrlohUFW22WeOtmFSpCP/QDmYJ0eN3w0tVdkr3A4pLz4yCdQfV+UJP0hqw1e4OmAu8kpS6CutirPoD6g9aQlJrUb4igLvVqbbX6qV+902od7eP7+xbyd8yv+7m5Qs0Uyhtc/RoLrnzpiKlJ58BuJ8etxKiVsP7HEdMrld2Z/B+dxYsZZD8SZpCz+tkvKvME81u3MlKZiZbV5QyUUsMeZcgHZo+GUhq0k7qcWZrCM8xhAoPs7etlk56mYlbydHQemZCn43PCShB2grAuCJMMO+nY18v46IIYnoWm1IcVRXpK4cXI4TyN/LRKrsjAm7hcJGD5bPGZHt6SQIgat6TCRx9LUVCK9a/jRoW0a6lvneyum1/rwouRw3ga+W2XPbM0N7idU/7xVNk65YXJu5CbGkV3wM1gfqlzC5if64UZLGzsm51TWVvl/ZDDBRZ5BowHWE9jdzDnaawjJt1u+jtgNoDeAnqj0RuNnXXTnsZsAeup9EqlFo/0tptAr5KIHzv2hzFfCPWF0EA4+DYSDBxyEgifHEUwfwQ7Ig58WPANjvpx9DWO+nDUd/zuVYr9B17sUj3rZFNZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40ca4212eb39a552d723bf442ee7cb97/e4706/OpenHabian_CLI_08.avif 230w", "/en/static/40ca4212eb39a552d723bf442ee7cb97/d1af7/OpenHabian_CLI_08.avif 460w", "/en/static/40ca4212eb39a552d723bf442ee7cb97/b6582/OpenHabian_CLI_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/40ca4212eb39a552d723bf442ee7cb97/a0b58/OpenHabian_CLI_08.webp 230w", "/en/static/40ca4212eb39a552d723bf442ee7cb97/bc10c/OpenHabian_CLI_08.webp 460w", "/en/static/40ca4212eb39a552d723bf442ee7cb97/87ca7/OpenHabian_CLI_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40ca4212eb39a552d723bf442ee7cb97/81c8e/OpenHabian_CLI_08.png 230w", "/en/static/40ca4212eb39a552d723bf442ee7cb97/08a84/OpenHabian_CLI_08.png 460w", "/en/static/40ca4212eb39a552d723bf442ee7cb97/e9beb/OpenHabian_CLI_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/40ca4212eb39a552d723bf442ee7cb97/e9beb/OpenHabian_CLI_08.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose `}<em parentName="p">{`Change Passwords`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3c7a2436161052533feb3ce83b2b3991/e9beb/OpenHabian_CLI_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.521739130434778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABMUlEQVQY0wXB206CYAAAYN6zh0gHbl50Adg0wCZrIxVPKP2omAfmdM4uiosmpz/4QRw1c+UsB9WFb9D3YY+hvg016I4gHCJnFIWzF/PetYeOodqGahqqYwysVd82B3A18OEYoYmLtMDq5aZjzHt7OOnSa5PftEpb+ebQLbtVJpJ4VGH9KutVmKjNRy0+apaiVukDCPuO8AUqv8+NQr+JGcHiaMrhsuEvajtd3ukdayLAadmbVr2ZaGu33qyK5qI1Eby5uFk2grn4/iR/W5282sZMqJ38u2TdS3wlDpTYB3tHOiLws+7GgXJEIPbBAbY/bSlGIEEgCbpx2Puz6wWljuGyRBQu8GKOKNJZPk9wNM7RKZZKMxTOkgRHZjiKYMjMdS5TvExfkSmGSjH0OZ09E2r/SxHOcQnyICoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c7a2436161052533feb3ce83b2b3991/e4706/OpenHabian_CLI_09.avif 230w", "/en/static/3c7a2436161052533feb3ce83b2b3991/d1af7/OpenHabian_CLI_09.avif 460w", "/en/static/3c7a2436161052533feb3ce83b2b3991/b6582/OpenHabian_CLI_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3c7a2436161052533feb3ce83b2b3991/a0b58/OpenHabian_CLI_09.webp 230w", "/en/static/3c7a2436161052533feb3ce83b2b3991/bc10c/OpenHabian_CLI_09.webp 460w", "/en/static/3c7a2436161052533feb3ce83b2b3991/87ca7/OpenHabian_CLI_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c7a2436161052533feb3ce83b2b3991/81c8e/OpenHabian_CLI_09.png 230w", "/en/static/3c7a2436161052533feb3ce83b2b3991/08a84/OpenHabian_CLI_09.png 460w", "/en/static/3c7a2436161052533feb3ce83b2b3991/e9beb/OpenHabian_CLI_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3c7a2436161052533feb3ce83b2b3991/e9beb/OpenHabian_CLI_09.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And go through all options to change the default logins:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a437277b8b93bc1349ad080f24ce8bd1/e9beb/OpenHabian_CLI_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACEUlEQVQozx2La0/TYACF+wPBMCDR2ckHYibhIpgYPmswuA27zcGEGIjhOtdubDBkvdAydum6tX3ftm+7IuImtINgMhSM301NTk6e5yQH22wKokSVG9mylBXlXLWR5atkWSArfLp2TILmPmjsS82cKOdEJV9X9sRmrq7kCyVyVACYaHxRa3uftMZnJO4gad2QNtSTVbm8pZxsguo6rG3D2ib0YANWd0BlVanlUdVi3uPkIVZHzJqNplvXL+3ulH0zhq5mW84L053UfkzC9oR6/hx+H/e4M6O1p8H5aLP9RrcB++ExWcQaiF6zjafoesZ0piw3qF+M65cT2kVQ7QTV9jPQ8RQ5U8id0C/HlPaI3InopsYmcco7F5dayAduRoCLw65fcf7HDYBuAF4FvMX1Ky6uuo9kB1ccn9x9rZ8a7GKAKmIlWDRQgYbskcYIOlsy+bIpCDpXQjwHWRYwLGCONJaH7BFkOMAwKiObjM4ln2RpjIf039P0/dnun7Pd+6/ZW4u8gts/UfrWpnot6pdN3tnUDdzqodT9aea3nelZ5N23vHH8Ec/Q2EElc8YlkLDSOl7RuKRcTNQLUUAnELdkcouW10nlIAbpRKu0YvHLRnHR4peFrVf+VAHzpwp9c6H+UPRBiPCFiaFIdDAcHQxFfSFiKBIbXIgPhGPD7+LDC/H+eWLgLeGbJ3yhaN9c+OGh9A/VhHkHPHv+WwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a437277b8b93bc1349ad080f24ce8bd1/e4706/OpenHabian_CLI_10.avif 230w", "/en/static/a437277b8b93bc1349ad080f24ce8bd1/d1af7/OpenHabian_CLI_10.avif 460w", "/en/static/a437277b8b93bc1349ad080f24ce8bd1/b6582/OpenHabian_CLI_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a437277b8b93bc1349ad080f24ce8bd1/a0b58/OpenHabian_CLI_10.webp 230w", "/en/static/a437277b8b93bc1349ad080f24ce8bd1/bc10c/OpenHabian_CLI_10.webp 460w", "/en/static/a437277b8b93bc1349ad080f24ce8bd1/87ca7/OpenHabian_CLI_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a437277b8b93bc1349ad080f24ce8bd1/81c8e/OpenHabian_CLI_10.png 230w", "/en/static/a437277b8b93bc1349ad080f24ce8bd1/08a84/OpenHabian_CLI_10.png 460w", "/en/static/a437277b8b93bc1349ad080f24ce8bd1/e9beb/OpenHabian_CLI_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a437277b8b93bc1349ad080f24ce8bd1/e9beb/OpenHabian_CLI_10.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After exiting the CLI tool, you can change the UNIX password (that you need to login to your Pi over SSH) by typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`passwd`}</code>{`, typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhabian`}</code>{` as your current password and then typing your new password and repeat:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/e9beb/OpenHabian_CLI_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABIElEQVQY0zXOXU6DQABFYXZS6cDMAG2xUCgO/0OhqZaYqDFGfXP/azhG1Icv9+3mOMe2pOxzii6l7FNOF0M/5IwnwzQ2WFszjA1Vc0ecbDkc9ySHHfE+QkcKHUlU6OMrgfBvcPrOMAwt1raMtublaeb6MPI4T1yvF3rbMc8Tp7FjPFtaW1O1hrK5Iy8STJlxPCbsky1BJHHeasN7k/PZ5Xx0GV9TyXuV8VoVPJcFcRqT/lRltxzy37o0i8kWO9IkIgy95UyHEqe3NZfLwHTusa1h3gbca59CCnbKRcgVUq7w5Q3CW6G1SxCu8aSLp9y/XSOku3CKrqIeDP3Z0JwKsiKirPa0iWATuGyiNZuNhw49pBYLpQWeEsvRP1+tUYHgG73aoS2DFhTnAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/e4706/OpenHabian_CLI_11.avif 230w", "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/d1af7/OpenHabian_CLI_11.avif 460w", "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/b6582/OpenHabian_CLI_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/a0b58/OpenHabian_CLI_11.webp 230w", "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/bc10c/OpenHabian_CLI_11.webp 460w", "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/87ca7/OpenHabian_CLI_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/81c8e/OpenHabian_CLI_11.png 230w", "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/08a84/OpenHabian_CLI_11.png 460w", "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/e9beb/OpenHabian_CLI_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed0991d67b4cefa8fc7e216a7ba56d27/e9beb/OpenHabian_CLI_11.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "openhab-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-setup",
        "aria-label": "openhab setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Setup`}</h2>
    <p>{`We already walked through the first steps of creating `}<strong parentName="p">{`Items`}</strong>{` and `}<strong parentName="p">{`Sitemaps`}</strong>{` as well as accessing your Home Automation Dashboard over the Internet in the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/"
      }}>{`Windows-based tutorial`}</a>{`. All of that can be `}<em parentName="p">{`copy&paste`}</em>{` here. The corresponding configuration files can be copied into the respective folder in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/openhab2/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "704px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0fcaac691be2c6d770ee9181e632c0b6/5ebd7/OpenHAB_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACY0lEQVQoz02RV87bMBCEdRWziEVip3ovpFVip97/LIH/vATYhw8LDGZ2NmEZohzRDBsnbKEySVMGMUMkS2lOU45TjllOEEeIQUT/H5BQjlIGc819bV1tpRXCCmmFdFJ7aQtpXO5KLb+WTFBIwEdJIKYgSTnCHEkrTaF1oU1lTGX0F9tS161zhdJeKieUk0wQSP+JAWMgAQQAAiCFaZZqr01pmGRccuWVdsKWOs0wSB8wBQA/HviRUkDYx5YzmBSNM94ILXWhcpMLK7RXptDKSV9qYTJdaFdaX1rjtfHKFtp4iSlMCUye1/7+cT2P/bxiOJ9r3La43e9rP7bj2vc4H3cIV7jf1/2+isZ1UyNthijAFCa2dsXQVL1pZ1ktpppUMeS+k0VX1X01zO0SRlfqXGe55oQjLilmCFFAOEr6te+WwTXC1cS0zHbMdZksGHfK1L7sK1NoyjEgD/jlhhkk/HNznqMkxGmam6b2yzpUXd1PXTe2yql5n/qhWuemHWsqOWYIM8gyTDJEsg8TBpPr9TzO0DbFfYfzdV7v54/fr2Fuwx23bfh2zGuc1TzkXhKOWYaFJEISTCGkn8LC8wh1XZ7Hfr7O447f/7zHtQ/Htm3961q2OKqx012VaUY41IoqSdDn2yAJcd63qSlt3Ofj3OKxfHsf41Sfx7KtXdyHEAbRlm5ssgyRT3JIOaT8w8lx7SEuVWn3sJz3cd/x56/XMDXxuQ5Ts279tM9qnqp1EDlOP2LEM8Q5ohwmY1j6fbGVWmKzneN+9ef3tZ98t47VMvRxjZvnpROVSxlG5PEvMCIAM/AXWEao85kgWUMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fcaac691be2c6d770ee9181e632c0b6/e4706/OpenHAB_00.avif 230w", "/en/static/0fcaac691be2c6d770ee9181e632c0b6/d1af7/OpenHAB_00.avif 460w", "/en/static/0fcaac691be2c6d770ee9181e632c0b6/6b930/OpenHAB_00.avif 704w"],
              "sizes": "(max-width: 704px) 100vw, 704px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0fcaac691be2c6d770ee9181e632c0b6/a0b58/OpenHAB_00.webp 230w", "/en/static/0fcaac691be2c6d770ee9181e632c0b6/bc10c/OpenHAB_00.webp 460w", "/en/static/0fcaac691be2c6d770ee9181e632c0b6/9a172/OpenHAB_00.webp 704w"],
              "sizes": "(max-width: 704px) 100vw, 704px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fcaac691be2c6d770ee9181e632c0b6/81c8e/OpenHAB_00.png 230w", "/en/static/0fcaac691be2c6d770ee9181e632c0b6/08a84/OpenHAB_00.png 460w", "/en/static/0fcaac691be2c6d770ee9181e632c0b6/5ebd7/OpenHAB_00.png 704w"],
              "sizes": "(max-width: 704px) 100vw, 704px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0fcaac691be2c6d770ee9181e632c0b6/5ebd7/OpenHAB_00.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`E.g. we already created an `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/#homeitems"
      }}>{`Items File`}</a>{` and a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/#homesitemap"
      }}>{`Sitemap`}</a>{` as well as a configuration file for the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#connect-the-mqtt-server-to-openhab2"
      }}>{`MQTT Server`}</a>{`. All of the can be copied here to the `}<strong parentName="p">{`items`}</strong>{`, `}<strong parentName="p">{`sitemaps`}</strong>{` and `}<strong parentName="p">{`services`}</strong>{` folder respectively. The `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/#openhab-troubleshooting"
      }}>{`debug logs`}</a>{` can be found under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/var/log/openhab2/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "577px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e4203d9e6135647e47155cb5961f304/065e2/OpenHAB_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA9ElEQVQY0wXBCXaCMBQAQI5SKoIsEkjIQtafDwTQ6rO9/2E6k5l9Ga2ZIepALLYi1DzWMtYSaiK7RnIG1m2g0HMnhZ6GqeeKCkWlHLLX5wnoI3gluXNSaTapUVrBBJnkaCw3lq9ovBN0upd18V1+lbf8WuVVfcnOxxLAaMX0PD0fG8CMi00pUNYpPaUEiPo8MKWgNBtYP45d211vTdG0RRbQ+qAZI5QN78/r8XOuCX//3oQ0hBGI1noVQHuYMcF6bscZOe+LKi+bIrMrGHRMCyrv+wvwcJD08UaPvKeNic5ucV6DWsBt/Ll3RLTdUF+qvLhd/gFkBUJrVUj/QAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e4203d9e6135647e47155cb5961f304/e4706/OpenHAB_01.avif 230w", "/en/static/5e4203d9e6135647e47155cb5961f304/d1af7/OpenHAB_01.avif 460w", "/en/static/5e4203d9e6135647e47155cb5961f304/761db/OpenHAB_01.avif 577w"],
              "sizes": "(max-width: 577px) 100vw, 577px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e4203d9e6135647e47155cb5961f304/a0b58/OpenHAB_01.webp 230w", "/en/static/5e4203d9e6135647e47155cb5961f304/bc10c/OpenHAB_01.webp 460w", "/en/static/5e4203d9e6135647e47155cb5961f304/d261b/OpenHAB_01.webp 577w"],
              "sizes": "(max-width: 577px) 100vw, 577px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e4203d9e6135647e47155cb5961f304/81c8e/OpenHAB_01.png 230w", "/en/static/5e4203d9e6135647e47155cb5961f304/08a84/OpenHAB_01.png 460w", "/en/static/5e4203d9e6135647e47155cb5961f304/065e2/OpenHAB_01.png 577w"],
              "sizes": "(max-width: 577px) 100vw, 577px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e4203d9e6135647e47155cb5961f304/065e2/OpenHAB_01.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After copying these files in place, don't forget to install `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/#transformation-services"
      }}>{`Transformation Addons`}</a>{` inside the OpenHAB PaperUI and create the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./transform/mqtt-binding.map`}</code>{` file. To be able to work with the MQTT protocol, you need to install the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/#mqtt-binding-for-openhab"
      }}>{`MQTT Binding`}</a>{` Addon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      